import React from "react"
import Layout from "../components/layout"
import ResourceCard from "../components/ResourceCard"
import ResourcePagination from "../components/ResourcePagination"
import { Link } from "gatsby"
import SEO from "../components/seo"

const Blog = () => {
  return (
    <Layout>
      <SEO title="Tools | Resources" />

      <div className="header resources__header">
        <div className="container grid">
          <div className="header-content header-content--wide">
            <h1 className="resources__title">
              Industry experience to guide you
            </h1>
            <nav className="resources__breadcrumb">
              <Link to="/resources" className="link--arrow-left">
                Resources
              </Link>
              <Link to="/tools">Tools</Link>
            </nav>
          </div>
        </div>
      </div>

      <div className="resources-list container">
        <ResourceCard
          to="/tools/tool"
          img="https://via.placeholder.com/400"
          title="25% more listings with 50% less people"
          category="tool"
          tags={["E-commerce"]}
        />
        <ResourceCard
          to="/tools/tool"
          img="https://via.placeholder.com/400"
          title="25% more listings with 50% less people"
          category="tool"
          tags={["E-commerce"]}
        />
        <ResourceCard
          to="/tools/tool"
          img="https://via.placeholder.com/400"
          title="25% more listings with 50% less people"
          category="tool"
          tags={["E-commerce"]}
        />
        <ResourceCard
          to="/tools/tool"
          img="https://via.placeholder.com/400"
          title="25% more listings with 50% less people"
          category="tool"
          tags={["E-commerce"]}
        />
        <ResourceCard
          to="/tools/tool"
          img="https://via.placeholder.com/400"
          title="25% more listings with 50% less people"
          category="tool"
          tags={["E-commerce"]}
        />
        <ResourceCard
          to="/tools/tool"
          img="https://via.placeholder.com/400"
          title="25% more listings with 50% less people"
          category="tool"
          tags={["E-commerce"]}
        />
        <ResourceCard
          to="/tools/tool"
          img="https://via.placeholder.com/400"
          title="25% more listings with 50% less people"
          category="tool"
          tags={["E-commerce"]}
        />
        <ResourceCard
          to="/tools/tool"
          img="https://via.placeholder.com/400"
          title="25% more listings with 50% less people"
          category="tool"
          tags={["E-commerce"]}
        />
      </div>

      <ResourcePagination basePath="/tools" pages={[1, 2, 3]} nextPage={2} />
    </Layout>
  )
}

export default Blog
